<!-- eslint-disable vue/valid-define-props -->
<script setup lang="ts">
import type { NavigationLink } from 'global'
import { useFavicon } from '@vueuse/core'
import {
  BibleVersionIds,
  bibleVersionsDutch,
  bibleVersionsFinnish,
  bibleVersionsEnglish,
  bibleVersionsPortuguese,
} from '~/enums'
import { useBibleStore } from '~/store/bible'

const runtimeConfig = useRuntimeConfig()
const env = runtimeConfig.public.env

const { t } = useI18n()
const { locale, locales } = useI18n()
const switchLocalePath = useSwitchLocalePath()

const availableLocales = computed(() => {
  return locales.value.filter((i) => i.code !== locale.value)
})

const bibleVersions = computed(() => {
  switch (locale.value) {
    case 'nl-NL':
      return bibleVersionsDutch
    case 'fi-FI':
      return bibleVersionsFinnish
    case 'en-US':
      return bibleVersionsEnglish
    case 'pt-PT':
      return bibleVersionsPortuguese
    default:
      return bibleVersionsDutch
  }
})

const bibleVersionId = computed(() => {
  const version = t('default_bible')
  return BibleVersionIds[version as keyof typeof BibleVersionIds]
})

const props = defineProps({
  navigation: {
    type: Array as () => NavigationLink[],
    required: false,
    default: () => [],
  },
  showBibleNav: {
    type: Boolean,
    required: false,
    default: false,
  },
})

const bibleStore = useBibleStore()
const {
  getBible,
  setCurrentBibleBook,
  setCurrentBibleChapter,
  setCurrentChapterContent,
  setDefaultBibleBookAndChapter,
} = bibleStore

const { books, currentBookName, currentChapter } = storeToRefs(bibleStore)
try {
  await getBible(bibleVersionId.value, t('brand')) // Make sure the bible is loaded
  await setCurrentChapterContent(t('brand'))
} catch (e) {
  console.log(e)
}

watch(
  () => t('brand'),
  async () => {
    try {
      const icon = useFavicon()
      icon.value = t('favicon')
      await getBible(bibleVersionId.value, t('brand')) // Load new Bible when language is switched
      await setCurrentChapterContent(t('brand'))
    } catch (e) {
      setDefaultBibleBookAndChapter()
      await setCurrentChapterContent(t('brand'))
    }
    selectedBibleVersion.value = bibleVersions.value?.[0]
  },
)

const basicBibleVersion = computed(() => t('default_bible'))

const showBibleVersions = ref(false)
const selectedBibleVersion = ref(bibleVersions.value[0])

const setBibleVersion = async (
  bibleVersion = BibleVersionIds[
    basicBibleVersion.value as keyof typeof BibleVersionIds
  ],
) => {
  selectedBibleVersion.value = bibleVersion
  const bibleId =
    BibleVersionIds[bibleVersion as unknown as keyof typeof BibleVersionIds]
  try {
    await getBible(bibleId, t('brand'))
    await setCurrentChapterContent(t('brand'))
  } catch (e) {
    setDefaultBibleBookAndChapter()
    await setCurrentChapterContent(t('brand'))
  }
  showBibleVersions.value = false
}

const showBooks = ref(false)
const bookNames = computed(() => books.value?.map((book) => book?.name))
const selectedBookName = ref(currentBookName)
const selectedBook = computed(() =>
  books.value?.find((book) => book?.name === selectedBookName.value),
)
const setBook = (bookName: string) => {
  selectedBookName.value = bookName
  showBooks.value = false
  showChapterNumbers.value = true
}

const showChapterNumbers = ref(false)
const selectedChapterNumber = ref(currentChapter)
const chapterNumbers = computed(() =>
  selectedBook.value?.chapters?.map((chapter) => chapter.number),
)
const setChapter = async (chapterNumber: string) => {
  setCurrentBibleBook(selectedBookName.value)
  setCurrentBibleChapter(chapterNumber)
  await setCurrentChapterContent(t('brand'))
  selectedChapterNumber.value = chapterNumber
  showChapterNumbers.value = false
}

const previousChapter = async () => {
  const previousChapterNumber = +selectedChapterNumber.value - 1
  if (!previousChapterNumber || previousChapterNumber === 0) return
  await setChapter(previousChapterNumber.toString())
}

const nextChapter = async () => {
  const nextChapterNumber = +selectedChapterNumber.value + 1
  const lastChapter =
    chapterNumbers.value?.[chapterNumbers.value.length - 1] || -1
  if (
    lastChapter === -1 ||
    !nextChapterNumber ||
    nextChapterNumber > +lastChapter
  )
    return
  await setChapter(nextChapterNumber.toString())
}
</script>
<template>
  <div class="navbar bg-base-100">
    <div class="navbar-start w-auto md:flex md:grow">
      <div class="dropdown">
        <div tabindex="0" role="button" class="btn btn-ghost btn-circle">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-5 w-5"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M4 6h16M4 12h16M4 18h7"
            />
          </svg>
        </div>
        <ul
          tabindex="0"
          class="menu menu-sm dropdown-content mt-3 z-[1] p-2 shadow bg-base-100 rounded-box w-52"
        >
          <li
            v-for="(link, index) in navigation"
            :key="`nav-${index}`"
            class="my-4 text-lg"
          >
            <Nuxt-Link :to="link.url" class="py-4">{{ link.label }}</Nuxt-Link>
          </li>
        </ul>
      </div>
    </div>
    <div v-show="props.showBibleNav" class="navbar-center md:w-auto w-auto">
      <ClientOnly>
        <button
          class="btn hidden md:block dropdown btn-sm px-1 sm:btn-md mr-4 sm:mr-16"
          @click="previousChapter()"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M15.75 19.5 8.25 12l7.5-7.5"
            />
          </svg>
        </button>
        <div
          v-show="!showBooks"
          class="dropdown dropdown-bottom -mr-1 sm:mr-0"
          @click="showBooks = true"
        >
          <div tabindex="0" role="button" class="btn btn-sm px-1 sm:btn-md m-1">
            {{ currentBookName }}
          </div>
        </div>
        <div
          v-show="showBooks"
          class="dropdown dropdown-bottom dropdown-open -mr-1 sm:mr-0"
          @click="showBooks = false"
        >
          <div tabindex="0" role="button" class="btn btn-sm px-1 sm:btn-md m-1">
            {{ currentBookName }}
          </div>
          <ul
            tabindex="0"
            class="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box grid grid-cols-1 gap-4 w-36 md:w-52 overflow-auto max-h-64"
          >
            <li v-for="(bookName, index) in bookNames" :key="`book-${index}`">
              <a @click="setBook(bookName)">{{ bookName }}</a>
            </li>
          </ul>
        </div>
        <div
          v-show="!showChapterNumbers || showBooks"
          class="dropdown dropdown-bottom"
          @click="showChapterNumbers = true"
        >
          <div tabindex="0" role="button" class="btn btn-sm px-3 sm:btn-md m-1">
            {{ currentChapter }}
          </div>
        </div>
        <div
          v-show="showChapterNumbers && !showBooks"
          class="dropdown dropdown-bottom dropdown-open"
          @click="showChapterNumbers = false"
        >
          <div tabindex="0" role="button" class="btn btn-sm px-3 sm:btn-md m-1">
            {{ currentChapter }}
          </div>
          <ul
            tabindex="0"
            class="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box grid grid-cols-3 md:grid-cols-5 gap-0 w-36 md:w-72 overflow-y-auto max-h-64"
          >
            <li
              v-for="(chapterNumber, index) in chapterNumbers"
              :key="`chapter-${index}`"
              class="w-8"
            >
              <a @click.stop="setChapter(chapterNumber)">{{ chapterNumber }}</a>
            </li>
          </ul>
        </div>
        <div
          v-show="!showBibleVersions || showBooks || showChapterNumbers"
          class="dropdown dropdown-bottom -ml-1 sm:ml-0"
          @click="showBibleVersions = true"
        >
          <div
            tabindex="0"
            role="button"
            class="btn m-1 btn-sm px-1 text-xs sm:text-normal sm:btn-md"
          >
            {{ selectedBibleVersion }}
          </div>
        </div>
        <div
          v-show="showBibleVersions && !showBooks && !showChapterNumbers"
          class="dropdown dropdown-bottom dropdown-open -ml-1 sm:ml-0 text-xs"
          @click="showBibleVersions = false"
        >
          <div
            tabindex="0"
            role="button"
            class="btn btn-sm text-xs sm:text-normal px-1 sm:btn-md m-1"
          >
            {{ selectedBibleVersion }}
          </div>
          <ul
            tabindex="0"
            class="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box grid grid-cols gap-0 w-52 overflow-y-auto h-52"
          >
            <li
              v-for="(bibleVersion, index) in bibleVersions"
              :key="`bible-version-${index}`"
              class="w-8"
            >
              <a @click.stop="setBibleVersion(bibleVersion as BibleVersionIds)">
                {{ bibleVersion }}
              </a>
            </li>
          </ul>
        </div>
        <button
          class="btn hidden md:block dropdown btn-sm px-1 sm:btn-md ml-4 sm:ml-16"
          @click="nextChapter()"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="m8.25 4.5 7.5 7.5-7.5 7.5"
            />
          </svg>
        </button>
      </ClientOnly>
    </div>
    <div class="navbar-end hidden md:flex">
      <NuxtLink
        v-for="locale in availableLocales"
        :key="locale.code"
        :to="switchLocalePath(locale.code)"
        class="btn btn-ghost"
      >
        {{ locale?.flag }} {{ locale?.name }}
      </NuxtLink>
      <ChatPromptDrawer v-if="env !== 'production'" class="w-max-12" />
    </div>
  </div>
</template>
