<script setup lang="ts">
import { useConfigStore } from '~/store/config'

const configStore = useConfigStore()
const {
  setPrompt,
  setUseCustomPrompt,
  setOriginalPrompt,
  setSelectedModel,
  setDatabase,
  setNumRelevantDocs,
} = configStore

const { originalPrompt, selectedModel, numRelevantDocs, database } =
  storeToRefs(configStore)

const { t } = useI18n()
setOriginalPrompt(useGetSystemPrompt(t))

const databases = await $fetch('/api/pinecone/indexes', {
  method: 'GET',
})

watch(
  () => t('brand'),
  () => {
    setOriginalPrompt(useGetSystemPrompt(t))
  },
)

const newPrompt = ref('')

const isChecked = ref(false)

watch(
  () => newPrompt.value,
  () => {
    savePrompt()
  },
)

const savePrompt = () => {
  setPrompt(newPrompt.value)
}

watch(
  () => isChecked.value,
  () => {
    setUseCustomPrompt(isChecked.value)
  },
)

const useCustomPrompt = () => {
  setUseCustomPrompt(isChecked.value)
}

const model = ref(selectedModel)
watch(
  () => model.value,
  () => {
    setSelectedModel(model.value)
  },
)

const relevantDocs = ref(numRelevantDocs)
watch(
  () => relevantDocs.value,
  () => {
    setNumRelevantDocs(Number(relevantDocs.value))
  },
)

const db = ref(database)
watch(
  () => db.value,
  () => {
    setDatabase(db.value)
  },
)
</script>

<template>
  <div class="drawer drawer-end w-24 bg-white">
    <input id="my-drawer" type="checkbox" class="drawer-toggle" />
    <div class="drawer-content">
      <!-- Page content here -->
      <label for="my-drawer" class="btn btn-ghost drawer-button btn-sm"
        ><svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-6 h-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.325.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 0 1 1.37.49l1.296 2.247a1.125 1.125 0 0 1-.26 1.431l-1.003.827c-.293.241-.438.613-.43.992a7.723 7.723 0 0 1 0 .255c-.008.378.137.75.43.991l1.004.827c.424.35.534.955.26 1.43l-1.298 2.247a1.125 1.125 0 0 1-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.47 6.47 0 0 1-.22.128c-.331.183-.581.495-.644.869l-.213 1.281c-.09.543-.56.94-1.11.94h-2.594c-.55 0-1.019-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 0 1-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 0 1-1.369-.49l-1.297-2.247a1.125 1.125 0 0 1 .26-1.431l1.004-.827c.292-.24.437-.613.43-.991a6.932 6.932 0 0 1 0-.255c.007-.38-.138-.751-.43-.992l-1.004-.827a1.125 1.125 0 0 1-.26-1.43l1.297-2.247a1.125 1.125 0 0 1 1.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.086.22-.128.332-.183.582-.495.644-.869l.214-1.28Z"
          />
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
          />
        </svg>
      </label>
    </div>
    <div class="drawer-side z-10">
      <label
        for="my-drawer"
        aria-label="close sidebar"
        class="drawer-overlay"
      ></label>
      <div class="flex flex-col bg-white h-full px-8 pt-8">
        <div class="form-control w-96 flex justify-end">
          <div class="w-full flex flex-col mb-8">
            <label class="cursor-pointer label">
              <span class="label-text text-lg font-medium">Model</span>
            </label>
            <select v-model="model" class="select select-bordered w-full">
              <option disabled>Select your model</option>
              <option selected>GPT-4o</option>
              <option disabled>GPT-4</option>
              <option disabled>GPT-3.5 turbo</option>
              <option disabled>Llama-3</option>
            </select>
          </div>
          <div class="w-full flex flex-col mb-8">
            <label class="cursor-pointer label">
              <span class="label-text text-lg font-medium">Database</span>
            </label>
            <select v-model="db" class="select select-bordered w-full">
              <option
                v-for="(database, index) in databases"
                :key="`db-${index}`"
              >
                {{ database }}
              </option>
            </select>
          </div>
          <div class="w-full flex flex-col mb-8">
            <label class="cursor-pointer label">
              <span class="label-text text-lg font-medium"
                >Number of relevant documents</span
              >
            </label>
            <select
              v-model="relevantDocs"
              class="select select-bordered w-full"
            >
              <option>1</option>
              <option>2</option>
              <option>3</option>
              <option>4</option>
              <option selected>5</option>
              <option>6</option>
              <option>7</option>
              <option>8</option>
              <option>9</option>
              <option>10</option>
              <option>11</option>
            </select>
          </div>
          <label class="cursor-pointer label">
            <span class="label-text text-lg font-medium">{{
              $t('use_custom_prompt')
            }}</span>
            <input
              v-model="isChecked"
              type="checkbox"
              class="toggle toggle-primary"
              :checked="isChecked"
              @click="useCustomPrompt"
            />
          </label>
          <div>
            <textarea
              v-model="newPrompt"
              :placeholder="!isChecked ? originalPrompt : 'Voer een prompt in'"
              class="textarea textarea-bordered textarea-lg w-full h-96"
            ></textarea>
          </div>
        </div>
        <button
          class="text-left btn btn-xl rounded-full btn-outline dark:bg-gray-800 dark:text-gray-100 dark:hover:text-gray-800 dark:hover:bg-gray-100 hover:bg-sky-50 hover:text-blue-800 mt-24"
          @click="navigateTo('/upload')"
        >
          Upload data
        </button>
      </div>
    </div>
  </div>
</template>
